import React from 'react';

/**
 * Custom component for rendering Notes field with proper text wrapping
 * and no line break indicators
 * 
 * @param {Object} props Component props
 * @param {string} props.text The text content to display
 * @returns {JSX.Element} Rendered component
 */
const NotesField = ({ text }) => {
  if (!text) return <span> </span>;
  
  return (
    <div 
      style={{
        fontFamily: "Poppins, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "0.5px",
        textAlign: "left",
        width: "100%",
        wordBreak: "break-word",
        overflowWrap: "break-word",
        whiteSpace: "normal",
        overflow: "visible"
      }}
    >
      {text}
    </div>
  );
};

export default NotesField;