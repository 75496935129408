import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Grid, Button } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import ViewMore from "../viewmore/ViewMore";
import TableRow from "@mui/material/TableRow";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import { fetchShipments } from "../../api/shipmentTable";
import { useTheme } from "@mui/material/styles";
import config from "../../api/config";
import InfiniteScroll from "react-infinite-scroll-component";
import {
	formatDeliveryStatus,
	formatMultipleValues,
	convertUTCTimeToLocalTime,
	roundToTwoDecimalPlaces,
	removeDuplicateShipments,
	getColorCodeBarForShipments,
} from "../../utilities/shipmentTable";
import { useToastContext } from "../../context/toast";
import NotesField from '../Reusables/Note';

function Row(props) {
	const { row, index, handleSave, loggedInUser, columns } = props;

	const formattedLocalUpdatedTime = convertUTCTimeToLocalTime(
		row?.UpdatedTime || row.ShipmentDetails.UpdatedTime || row.ShipmentDetails?.CreatedTime,
	);

	const [open, setOpen] = useState(false);
	const [viewMorePopupOpen, setviewMorePopupOpen] = useState(false);
	const theme = useTheme();

	const handleViewMorePopupOpen = () => {
		setviewMorePopupOpen(true);
	};

	const handleViewMorePopupClose = () => {
		setviewMorePopupOpen(false);
	};

	const cellBorderStyle = getColorCodeBarForShipments(row)

	return (
		<>
			<TableRow
				sx={{
					"& > *": {
						backgroundColor:
							index % 2 === 0 ? theme.palette.primary.main : theme.palette.secondary.main,
					},
				}}
			>
				{columns.map((column) => (
					<TableCell
						key={column.id}
						sx={{
							width: column.width,
							padding: "12px 8px",
							// Apply the styles conditionally
							...(column.id === "DeliveryNumber" ? cellBorderStyle : {}),
							textAlign: column.id === "DeliveryNumber" ? "left" : "left",
							whiteSpace: "normal",
							wordBreak: "break-word",
							verticalAlign: "top",
						}}
					>
						{column.id === "DeliveryNumber" ? (
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<IconButton size="small" onClick={() => setOpen(!open)} sx={{ marginRight: "8px" }}>
									{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
								<span>{formatMultipleValues(row.DeliveryNumber)}</span>
							</Box>
						) : column.id === "PONumber" ? (
							formatMultipleValues(row.ShipmentDetails?.PONumber)
						) : column.id === "DeliveryAppointment" ? (
							row.DeliveryAppointment ? (
								<Box>
									<div>
										{moment(row.DeliveryAppointment, "YYYYMMDDHHmmss").format("MM/DD/YYYY")}
									</div>
									<div>{moment(row.DeliveryAppointment, "YYYYMMDDHHmmss").format("HH:mm")}</div>
								</Box>
							) : (
								""
							)
						) : column.id === "ShipDate" ? (
							row.ShipmentDetails.ShipDate ? (
								<Box>
									<div>
										{moment(row.ShipmentDetails.ShipDate, "YYYYMMDDHHmmss").format("MM/DD/YYYY")}
									</div>
									<div>
										{moment(row.ShipmentDetails.ShipDate, "YYYYMMDDHHmmss").format("HH:mm")}
									</div>
								</Box>
							) : (
								""
							)
						) : column.id === "OrgName" ? (
							row.ShipmentDetails.OrgName
						) : column.id === "City" ? (
							row.City
						) : column.id === "DeliveryStatus" ? (
							formatDeliveryStatus(row.DeliveryStatus)
						) : column.id === "Carrier" ? (
							row.ShipmentDetails.Carrier
						) : null}
					</TableCell>
				))}
			</TableRow>
			<TableRow
				sx={{
					"& > *": {
						backgroundColor:
							index % 2 === 0 ? theme.palette.primary.main : theme.palette.secondary.main,
					},
				}}
			>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box
							sx={{
								marginLeft: "200px",
								marginTop: "20px",
								paddingBottom: "16px",
								borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
							}}
						>
							<Grid container spacing={2}>
								{[
									["NB Number", row.NBNumber],
									["Preload", row.ShipmentDetails.Preload],
									["Transit Time", row.ShipmentDetails.TransitTime],
									["Distance", roundToTwoDecimalPlaces(row.ShipmentDetails.Distance)],
									["Pick-up ETA", row.ShipmentDetails.PickupEta],
									["Pick-up Status", row.PickupStatus],
									["State", row.State],
									["Pick-up Appointment", row.ShipmentDetails.PickupAppointment],
									["Delivery ETA", row.DeliveryEta],
									["Delivery Time", row.DeliveryTime],
									["Reason Code", row.ReasonCode],
									[
										"Tracker",
										row?.UpdatedBy ||
											row.ShipmentDetails?.UpdatedBy ||
											row.ShipmentDetails?.CreatedBy,
									],
									["Last Update Time", formattedLocalUpdatedTime],
									["Pick-up Time", row.ShipmentDetails.PickupTime],
									["Notes", row.Notes],
									["Warehouse", row.WareHouseNbr],
									["Comment", row.Comment],
								].map(([title, value]) => (
									<Grid item xs={12} sm={6} md={5} lg={3} key={title}>
										<Box
											sx={{
												width: "100%",
												boxSizing: "border-box",
												marginBottom: "8px",
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
												justifyContent: "center",
											}}
										>
											<Typography
												sx={{
													fontWeight: 500,
													fontFamily: "Poppins, sans-serif",
													fontSize: "16px",
													lineHeight: "24px",
													textAlign: "left",
												}}
											>
												{title}
											</Typography>

											{title === "Notes" ? (
                                              <NotesField text={value} />
                                                  ) : (
												<Typography
													sx={{
														fontFamily: "Poppins, sans-serif",
														fontSize: "16px",
														fontWeight: 400,
														lineHeight: "24px",
														letterSpacing: "0.5px",
														textAlign: "left",
													}}
												>
													{[
														"Pick-up ETA",
														"Pick-up Appointment",
														"Delivery ETA",
														"Delivery Time",
														"Reschedule Date",
														"Pick-up Time",
													].includes(title)
														? value
															? moment(value, "YYYYMMDDHHmmss").format("MM/DD/YYYY HH:mm:ss")
															: ""
														: value}
												</Typography>
									)}
										</Box>
									</Grid>
								))}
							</Grid>

							<Box sx={{ textAlign: "center", marginTop: 2 }}>
								<Button
									onClick={handleViewMorePopupOpen}
									variant="text"
									sx={{
										fontFamily: "Poppins, sans-serif",
										color: theme.palette.text.button,
										marginLeft: "auto",
										marginRight: "auto",
										border: theme.palette.border.main,
										"&:hover": {
											backgroundColor: theme.palette.buttonhover.main,
											color: theme.palette.text.buttonhover,
										},
									}}
								>
									View/Edit More
								</Button>
							</Box>
							<ViewMore
								open={viewMorePopupOpen}
								handleClose={handleViewMorePopupClose}
								rowData={row}
								onSave={handleSave}
								loggedInUser={loggedInUser}
							/>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

export default function CollapsibleTable({
	appliedFilters,
	onRowsChange,
	accountInfo,
	onFetchError,
	setIsSaveBtnClicked,
}) {
	const [rows, setRows] = useState([]);
	const [error, setError] = useState(null);
	const [sortBy, setSortBy] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isSorting, setIsSorting] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [allFetchedRows, setAllFetchedRows] = useState([]);
	const theme = useTheme();
	const { showErrorToast } = useToastContext();

	const fetchData = useCallback(
		async (isInitialFetch = false) => {
			if (!hasMore && !isInitialFetch) return;

			try {
				setIsLoading(true);
				const filters = appliedFilters || {};
				const data = await fetchShipments(
					filters,
					sortBy,
					sortOrder,
					isInitialFetch ? 1 : page,
					accountInfo,
				);

				onFetchError(false);
				setRows((prevRows) => {
					// If it's an initial fetch, just use the new data
					if (isInitialFetch) {
						const uniqueShipments = removeDuplicateShipments(data.shipments);
						setAllFetchedRows(uniqueShipments);
						onRowsChange(uniqueShipments);
						return uniqueShipments;
					}

					// For subsequent fetches, combine with existing data and deduplicate
					const combinedShipments = [...prevRows, ...data.shipments];
					const uniqueShipments = removeDuplicateShipments(combinedShipments);
					setAllFetchedRows(uniqueShipments);
					onRowsChange(uniqueShipments);
					return uniqueShipments;
				});

				// Check for invalid warehouse numbers in the response
				// if (data.invalidWarehouseNumbers && data.invalidWarehouseNumbers.length > 0) {
				//   // showErrorToast(`Invalid warehouse numbers: ${data.invalidWarehouseNumbers.join(', ')}`)
				//   showErrorToast("Enter valid Warehouse(s)")
				// }

				setHasMore(data.hasMore);
				setPage(data.nextPage);
				setError(null);
				setTotalCount(data.totalCount);
			} catch (error) {
				console.error("Error fetching shipments:", error);
				// if (error.response) {
				//   if (error.response.data) {
				//     if (Array.isArray(error.response.data.invalidWarehouseNumbers) && error.response.data.invalidWarehouseNumbers.length > 0) {
				//       showErrorToast("Enter valid Warehouse(s)");
				//     } else if (typeof error.response.data.invalidWarehouseNumbers === 'string' && error.response.data.invalidWarehouseNumbers.length > 0) {
				//       showErrorToast("Enter valid Warehouse(s)");
				//     }
				//   }
				// }
				onFetchError(true);
				setError(
					error.response?.status === 404
						? "No shipments are available"
						: "Failed to fetch shipments",
				);
				setHasMore(false);
			} finally {
				setIsLoading(false);
				setIsSorting(false);
			}
		},
		[appliedFilters, sortBy, sortOrder, page, hasMore, accountInfo], // Add accountInfo to dependencies
	);

	useEffect(() => {
        if (appliedFilters || sortBy || sortOrder) {
			setRows([]);
			setPage(1);
			setHasMore(true);
			setTotalCount(0);
			setError(null);
			fetchData(true);
		}
	}, [appliedFilters, sortBy, sortOrder]);

	useEffect(() => {
		const ws = new WebSocket(config.websocketURL);

		ws.onopen = () => {
			console.log("Connected to WebSocket server");
		};

		ws.onmessage = (event) => {
			const receivedData = JSON.parse(event.data);

			if (Array.isArray(receivedData)) {
				setError(null);
				setRows((prevRows) => {
					let updatedRows = [...prevRows];

					receivedData.forEach((incomingData) => {
						const existingRowIndex = updatedRows.findIndex(
							(row) =>
								row.DeliveryNumber === incomingData.DeliveryNumber &&
								row.NBNumber === incomingData.NBNumber &&
								row.StopSequence === incomingData.StopSequence,
						);

						if (existingRowIndex !== -1) {
							// Update existing row
							updatedRows[existingRowIndex] = {
								...updatedRows[existingRowIndex],
								...incomingData,
								ShipmentDetails: incomingData.ShipmentDetails,
							};
						} else {
							// Add new row
							updatedRows.push(incomingData);
						}
					});

					return updatedRows;
				});
			}
		};

		ws.onerror = (error) => {
			console.error("WebSocket error:", error);
		};

		return () => {
			ws.close();
		};
	}, []);

	const handleSort = useCallback(
		(columnId) => {
			if (isSorting) return;
			setIsSorting(true);
			if (sortBy === columnId) {
				setSortOrder(sortOrder === "ascending" ? "descending" : "ascending");
			} else {
				setSortBy(columnId);
				setSortOrder("ascending");
			}
		},
		[sortBy, sortOrder, isSorting],
	);

	const handleSave = async (updatedRowData) => {
		setIsSaveBtnClicked(true);
		try {
			// Update the rows state with the updated rowData
			setRows((prevRows) =>
				prevRows.map((row) => (row.NBNumber === updatedRowData.NBNumber ? updatedRowData : row)),
			);

			// Fetch the updated data from the server
			// const filters = appliedFilters || {};
			// setIsLoading(true);
			// const data = await fetchShipments(filters, sortBy, sortOrder);
			// setRows(data);
			// setError(null);
		} catch (error) {
			console.error("Error fetching shipments:", error);
			setError("Failed to fetch shipments");
		} finally {
			setIsLoading(false);
		}
	};

	const columns = [
		{ id: "DeliveryNumber", label: "Delivery No.", width: "12%", sortable: true },
		{ id: "PONumber", label: "PO Number", width: "12%", sortable: true },
		{ id: "DeliveryAppointment", label: "Delivery Appointment", width: "12%", sortable: true },
		{ id: "ShipDate", label: "Ship Date", width: "12%", sortable: true },
		{ id: "OrgName", label: "Source", width: "12%", sortable: false },
		{ id: "City", label: "City", width: "12%", sortable: false },
		{ id: "DeliveryStatus", label: "Delivery Status", width: "12%", sortable: true },
		{ id: "Carrier", label: "Carrier", width: "12%", sortable: false },
	];

	return (
		<Paper sx={{ width: "100%", overflow: "hidden", margin: "20px", marginBottom: 0 }}>
			{error ? (
				<Typography
					sx={{
						padding: "16px",
						fontSize: "24px",
						fontWeight: "medium",
						textAlign: "center",
						color: theme.palette.text.primary,
					}}
				>
					No shipments are available
				</Typography>
			) : (
				<>
					<Box sx={{ overflow: "auto", position: "relative" }}>
						<Table aria-label="collapsible table" stickyHeader>
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<TableCell
											key={column.id}
											sx={{
												fontWeight: "bold",
												width: column.width,
												backgroundColor: theme.palette.background.paper,
												zIndex: 1,
												padding: "16px 8px",
												borderBottom: `1px solid ${theme.palette.divider}`,
												textAlign: column.id === "DeliveryNumber" ? "center" : "left",
											}}
										>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: column.id === "DeliveryNumber" ? "center" : "flex-start",
												}}
											>
												<span>{column.label}</span>
												{column.sortable && (
													<ArrowDownwardOutlinedIcon
														sx={{
															color: isSorting ? "#ccc" : "#006098",
															fontSize: "16px",
															cursor: isSorting ? "not-allowed" : "pointer",
															transform:
																sortBy === column.id && sortOrder === "ascending"
																	? "rotate(180deg)"
																	: "rotate(0deg)",
															marginLeft: "4px",
														}}
														onClick={() => !isSorting && handleSort(column.id)}
													/>
												)}
											</Box>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
						</Table>
					</Box>
					<Box sx={{ height: "calc(91vh - 300px)", overflow: "auto" }} id="scrollableDiv">
						<InfiniteScroll
							dataLength={rows.length}
							next={() => fetchData(false)}
							hasMore={hasMore}
							loader={
								<Typography sx={{ textAlign: "center", padding: "16px" }}>Loading...</Typography>
							}
							scrollableTarget="scrollableDiv"
							initialLoad={false}
						>
							<Table>
								<TableBody>
									{rows.map((row, index) => (
										<Row
											key={`${row.DeliveryNumber}-${index}`}
											row={row}
											index={index}
											handleSave={handleSave}
											loggedInUser={accountInfo?.account?.name}
											columns={columns}
										/>
									))}
								</TableBody>
							</Table>
						</InfiniteScroll>
					</Box>
					{rows.length > 0 && (
						<Typography sx={{ textAlign: "center", padding: "16px" }}>
							Showing {rows.length} of {totalCount} shipments
						</Typography>
					)}
				</>
			)}
			{isLoading && rows.length === 0 && (
				<Typography sx={{ textAlign: "center", padding: "16px" }}>Loading...</Typography>
			)}
		</Paper>
	);
}
