export const regionMap = {
	"Region 1": "1 - Los Angeles",
	"Region 2": "2 - San Diego",
	"Region 3": "3 - Bay Area",
	"Region 4": "4 - Northeast",
	"Region 5": "5 - Southeast",
	"Region 6": "6 - Midwest",
	"Region 7": "7 - Texas",
	"Region 8": "8 - Northwest",
	"Costco Business Centers": "9 - Business Centers",
};

export const FILTER_ACTIONS = {
    saveFilter: "Save Filters",
    reset: "Reset to Default",
};

export const FILTER_MSG = {
    save_success: "Filters saved successfully",
    reset_success: "Filters reset to default",
    error: "Could not update filters. Please try again!",
}

export const SAVE_BUTTON_GROUP_OPTIONS = [ FILTER_ACTIONS.saveFilter, FILTER_ACTIONS.reset];
