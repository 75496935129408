import moment from "moment";
import React from "react";
import { validateEmptyOrNull } from "./libs";

// Shared utility functions
export const formatDeliveryStatus = (status) => {
	if (!status) return "";
	const statusMap = {
		Intransit: "In Transit",
	};
	return statusMap[status] || status;
};

export const formatMultipleValues = (value) => {
	if (!value) return "";

	// Split the input string by "|", trim each item, and create a Set to filter unique values
	const uniqueValues = [...new Set(value.split("|").map((item) => item.trim()))];

	return uniqueValues.map((item, index) => (
		<React.Fragment key={index}>
			{index > 0 && <br />}
			{item}
		</React.Fragment>
	));
};

export const convertUTCTimeToLocalTime = (UTCDate) => {
	var stillUtc = moment.utc(UTCDate, "YYYYMMDDHHmmss").toDate();
	const formattedLocalDate = moment.utc(stillUtc).local().format("MM/DD/YYYY HH:mm:ss");

	return formattedLocalDate ? formattedLocalDate : "";
};

export const roundToTwoDecimalPlaces = (value) => {
	const number = Number(value);
	if (isNaN(number)) {
		throw new Error("Input is not a valid number");
	}
	return Number(number.toFixed(2));
};

export const removeDuplicateShipments = (shipments) => {
	const uniqueMap = new Map();

	shipments.forEach((shipment) => {
		// Use composite key of just DeliveryNumber and NBNumber
		const key = `${shipment.DeliveryNumber}-${shipment.NBNumber}`;

		// Keep the most recently updated record
		if (
			!uniqueMap.has(key) ||
			(shipment.UpdatedTime &&
				uniqueMap.get(key).UpdatedTime &&
				shipment.UpdatedTime > uniqueMap.get(key).UpdatedTime)
		) {
			uniqueMap.set(key, shipment);
		}
	});

	return Array.from(uniqueMap.values());
};


// Define the missing function
export const convertDateStringToArray = (dateString) => {
	if (!dateString) return null;
	// If dateString is already an array, return it
	if (Array.isArray(dateString)) return dateString;
	// If dateString is a comma-separated string, split it and return as array
	if (typeof dateString === 'string' && dateString.includes(',')) {
		return dateString.split(',');
	}
	// If dateString is a single date string, return as an array with one element
	return [dateString];
}

export const getColorCodeBarForShipments = (row) => {
	if (row.DeliveryStatus === "Missed" && !validateEmptyOrNull(row.RescheduleDate)) {
		return {
			position: "relative", // Position relative for the pseudo-element
			"&::before": {
				content: '""',
				position: "absolute",
				left: 0,
				top: 0,
				width: "4px", 
				height: "100%", 
				background: "linear-gradient(to bottom, rgb(6, 160, 248) 50%, red 50%)", // Gradient from blue to red
			},
		};
	} else if (row.DeliveryStatus === "Missed") {
		return {
			position: "relative", // Position relative for the pseudo-element
			"&::before": {
				content: '""',
				position: "absolute",
				left: 0,
				top: 0,
				width: "4px", 
				height: "100%", 
				background: "red", // Solid red color for the border
			},
		};
	}

	return {
		borderLeft: "none",
	};
}

export const getLocalTime = () => {
    const now = new Date();
    // Format date with explicit options to ensure consistency across browsers
    // Use en-GB locale for DD/MM/YYYY format
    const formattedDate = now.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
    // Format time consistently
    const formattedTime = now.toLocaleTimeString('en-GB', { 
        hour12: false, 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit' 
    });
    
    const localTime = {
        date: formattedDate,
        time: formattedTime,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timezoneOffset: now.getTimezoneOffset()
    };
    
    return localTime;
};

