import React, { useState, useEffect } from "react";
import {
	Box,
	Typography,
	Button,
	Paper,
	IconButton,
	Popover,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";

const CustomDateRangePicker = ({ startDate, endDate, onDateChange, availableDates }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	// Initialize with the month of startDate if it exists, otherwise current month
	const [currentMonth, setCurrentMonth] = useState(
		startDate ? dayjs(startDate).startOf("month") : dayjs().startOf("month")
	);
	const [selectingStartDate, setSelectingStartDate] = useState(true);
	const [tempStartDate, setTempStartDate] = useState(startDate);
	const [tempEndDate, setTempEndDate] = useState(endDate);
	const [isValidRange, setIsValidRange] = useState(true);
	const theme = useTheme();

	const blueColor = "#006098";
	const lightBlueColor = "#E8F2FF";
	const darkModeBlueColor = "#1A7BC1"; // Darker blue for better visibility in dark mode
	const darkModeLightBlueColor = "#1E3A55"; // Darker light blue background for dark mode
	const errorColor = "#FF4D4F";
	const isDarkMode = theme.palette.mode === "dark";

	// Update currentMonth whenever startDate changes from parent
	useEffect(() => {
		if (startDate) {
			setCurrentMonth(dayjs(startDate).startOf("month"));
		}
	}, [startDate]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setTempStartDate(startDate);
		setTempEndDate(endDate);
		setSelectingStartDate(true);
		setIsValidRange(true);
		
		// Set the current month to the month of the start date when opening
		if (startDate) {
			setCurrentMonth(dayjs(startDate).startOf("month"));
		}
	};

	const handleClose = () => {
		// Always close the calendar when Cancel is clicked
		setAnchorEl(null);
		// Reset the validation state
		setIsValidRange(true);
	};

	// Function to generate array of all dates in the range
	const generateDateRange = (start, end) => {
		if (!start || !end) return [];
		
		const dateArray = [];
		let currentDate = dayjs(start); // Ensure we're working with a dayjs object
		
		// Include start date
		dateArray.push(currentDate);
		
		// Add all dates between start and end (inclusive)
		while (currentDate.isBefore(end, 'day')) {
			currentDate = currentDate.add(1, 'day');
			dateArray.push(currentDate);
		}
		
		return dateArray;
	};

	const handleApply = () => {
		// Only apply and close if the range is valid
		if (isValidRange && tempStartDate && tempEndDate) {
			// Generate all dates in the range to send to the parent component
			const dateRange = generateDateRange(tempStartDate, tempEndDate);
			onDateChange(tempStartDate, tempEndDate, dateRange);
			setAnchorEl(null);
		}
	};

	const validateDateRange = (start, end) => {
		if (!start || !end) return true;

		// Calculate days difference
		const diff = end.diff(start, "day") + 1; // +1 to include both start and end days
		return diff <= 5;
	};

	const handlePrevMonth = () => {
		setCurrentMonth(currentMonth.subtract(1, "month"));
	};

	const handleNextMonth = () => {
		setCurrentMonth(currentMonth.add(1, "month"));
	};

	const isDateAvailable = (date) => {
		return availableDates.some(
			(availableDate) => date.format("YYYY-MM-DD") === availableDate.format("YYYY-MM-DD"),
		);
	};

	const isDateInRange = (date) => {
		if (!tempStartDate || !tempEndDate) return false;
		return (date.isAfter(tempStartDate, "day") && date.isBefore(tempEndDate, "day")) ||
               date.format("YYYY-MM-DD") === tempStartDate.format("YYYY-MM-DD") ||
               date.format("YYYY-MM-DD") === tempEndDate.format("YYYY-MM-DD");
	};

	const handleDateClick = (date) => {
		if (!isDateAvailable(date)) return;

		if (selectingStartDate) {
			setTempStartDate(date);
			setTempEndDate(null);
			setSelectingStartDate(false);
			setIsValidRange(true);
		} else {
			let newStart, newEnd;

			if (date.isBefore(tempStartDate, "day")) {
				// If clicking a date before start date, swap them
				newEnd = tempStartDate;
				newStart = date;
			} else {
				newStart = tempStartDate;
				newEnd = date;
			}

			// Validate the range before setting
			const isValid = validateDateRange(newStart, newEnd);
			setIsValidRange(isValid);

			setTempStartDate(newStart);
			setTempEndDate(newEnd);
			setSelectingStartDate(true);
		}
	};

	const renderMonth = (monthDate) => {
		// Changed to Sunday start (0) instead of Monday (1)
		const firstDay = monthDate.startOf("month").day(); // This will now return 0 for Sunday
		const daysInMonth = monthDate.daysInMonth();
		const rows = Math.ceil((firstDay + daysInMonth) / 7);

		const days = [];

		// Updated week day headers to start with Sunday
		const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

		// Add empty cells for days of the week before the first day of the month
		let dayCellIndex = 0;

		for (let i = 0; i < rows; i++) {
			const row = [];
			for (let j = 0; j < 7; j++) {
				// Changed condition to handle Sunday start
				if ((i === 0 && j < firstDay) || dayCellIndex >= daysInMonth) {
					// Empty cell
					row.push(<Box key={`empty-${i}-${j}`} sx={{ width: 30, height: 30 }}></Box>);
				} else {
					dayCellIndex++;
					const date = monthDate.date(dayCellIndex);
					const isAvailable = isDateAvailable(date);
					const isStart =
						tempStartDate && date.format("YYYY-MM-DD") === tempStartDate.format("YYYY-MM-DD");
					const isEnd =
						tempEndDate && date.format("YYYY-MM-DD") === tempEndDate.format("YYYY-MM-DD");
					const isRange = isDateInRange(date);
					const isToday = date.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD");

					// Set colors based on theme mode
					const rangeBgColor = isDarkMode ? darkModeLightBlueColor : lightBlueColor;
					const startEndBgColor = isDarkMode ? darkModeBlueColor : blueColor;
					const rangeTextColor = isDarkMode ? "#ffffff" : blueColor;
					const unavailableTextColor = isDarkMode ? "rgba(255, 255, 255, 0.3)" : "lightgrey";

					row.push(
						<Box
							key={`day-${date.format("YYYY-MM-DD")}`}
							onClick={() => handleDateClick(date)}
							sx={{
								width: 30,
								height: 30,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								cursor: isAvailable ? "pointer" : "default",
								borderRadius: isStart || isEnd ? "50%" : isRange ? "0" : "50%",
								bgcolor: isStart || isEnd ? startEndBgColor : isRange ? rangeBgColor : "transparent",
								color:
									isStart || isEnd
										? "white"
										: isRange
											? rangeTextColor
											: !isAvailable
												? unavailableTextColor
												: theme.palette.text.primary,
								fontWeight: isToday ? "bold" : "normal",
								opacity: isAvailable ? 1 : 0.5,
								fontSize: "0.75rem",
								"&:hover": {
									bgcolor:
										isAvailable && !isStart && !isEnd
											? isRange
												? isDarkMode ? "rgba(255, 255, 255, 0.15)" : lightBlueColor
												: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "#f5f5f5"
											: isStart || isEnd
												? startEndBgColor
												: "transparent",
									// Ensure text remains visible in dark mode on hover
									color: isStart || isEnd 
										? "white" 
										: isRange && isDarkMode 
											? "#ffffff" 
											: isAvailable 
												? theme.palette.text.primary 
												: unavailableTextColor
								},
								position: "relative",
								"&::before": isRange && {
									content: '""',
									position: "absolute",
									top: 0,
									left: 0,
									right: 0,
									bottom: 0,
									backgroundColor: rangeBgColor,
									zIndex: -1,
								},
							}}
						>
							{dayCellIndex}
						</Box>,
					);
				}
			}
			days.push(
				<Box key={`row-${i}`} sx={{ display: "flex", justifyContent: "space-between", my: 0.25 }}>
					{row}
				</Box>,
			);
		}

		return (
			<Box>
				<Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
					{weekDays.map((day) => (
						<Box
							key={day}
							sx={{
								width: 30,
								textAlign: "center",
								fontWeight: "medium",
								color: "text.secondary",
								fontSize: "0.7rem",
							}}
						>
							{day}
						</Box>
					))}
				</Box>
				{days}
			</Box>
		);
	};

	// Using Popover which is more stable than Popper for this use case
	const open = Boolean(anchorEl);
	const id = open ? "date-range-popover" : undefined;

	return (
		<div>
			<Box
				onClick={handleClick}
				sx={{
					display: "flex",
					border: "1px solid",
					borderColor: open ? blueColor : theme.palette.border.main,
					borderRadius: 1,
					cursor: "pointer",
					"&:hover": {
						borderColor: blueColor,
					},
					overflow: "hidden",
				}}
			>
				<Box
			    sx={{
					flex: 1,
					p: 1,
					borderRight: "1px solid",
					borderColor: theme.palette.border.main,
				}}
				>
					<Typography
						variant="body2"
						color="text.secondary"
						sx={{ fontFamily: "Poppins, sans-serif", fontSize: "0.7rem" }}
					>
						Start Date
					</Typography>
					<Typography
						variant="subtitle2"
						sx={{ fontFamily: "Poppins, sans-serif", fontWeight: 500 }}
					>
						{startDate ? startDate.format("ddd, DD MMM YYYY") : "Select date"}
					</Typography>
				</Box>
				<Box
					sx={{
						flex: 1,
						p: 1,
					}}
				>
					<Typography
						variant="body2"
						color="text.secondary"
						sx={{ fontFamily: "Poppins, sans-serif", fontSize: "0.7rem" }}
					>
						End Date
					</Typography>
					<Typography
						variant="subtitle2"
						sx={{ fontFamily: "Poppins, sans-serif", fontWeight: 500 }}
					>
						{endDate ? endDate.format("ddd, DD MMM YYYY") : "Select date"}
					</Typography>
				</Box>
			</Box>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				PaperProps={{
					sx: { boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" },
				}}
			>
				<Paper
					elevation={0}
					sx={{
						p: 2,
						width: "280px",
						maxWidth: "100%",
						border: "none",
						boxShadow: "none",
					}}
				>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								mb: 1.5,
							}}
						>
							<IconButton onClick={handlePrevMonth} size="small">
								<ChevronLeftIcon fontSize="small" />
							</IconButton>
							<Typography variant="subtitle2" sx={{ fontFamily: "Poppins, sans-serif" }}>
								{currentMonth.format("MMMM YYYY")}
							</Typography>
							<IconButton onClick={handleNextMonth} size="small">
								<ChevronRightIcon fontSize="small" />
							</IconButton>
						</Box>

						{/* Date range hint */}
						<Typography
							variant="caption"
							sx={{
								fontFamily: "Poppins, sans-serif",
								mb: 1,
								color: isValidRange ? "text.secondary" : errorColor,
								fontWeight: isValidRange ? "normal" : "medium",
							}}
						>
							Please select a maximum of 5 days
						</Typography>

						{/* Single month view */}
						<Box sx={{ mb: 2 }}>{renderMonth(currentMonth)}</Box>

						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								mt: 1,
								borderTop: isDarkMode ? "1px solid rgba(255, 255, 255, 0.1)" : "1px solid #E8F2FF",
								paddingTop: 2,
							}}
						>
							<Button
								onClick={handleClose}
								sx={{
									textTransform: "none",
									fontFamily: "Poppins, sans-serif",
									color: isDarkMode ? "rgba(255, 255, 255, 0.7)" : "#757575",
									"&:hover": { 
										bgcolor: "transparent", 
										color: isDarkMode ? "#ffffff" : "#555555" 
									},
									padding: "6px 12px",
									minWidth: "80px",
								}}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								onClick={handleApply}
								disabled={!tempStartDate || !tempEndDate || !isValidRange}
								sx={{
									bgcolor: blueColor,
									"&:hover": { bgcolor: "#004d7a" },
									textTransform: "none",
									fontFamily: "Poppins, sans-serif",
									padding: "6px 16px",
									minWidth: "80px",
									borderRadius: "4px",
								}}
							>
								Apply
							</Button>
						</Box>
					</Box>
				</Paper>
			</Popover>
		</div>
	);
};

export default CustomDateRangePicker;

